

import { firestore } from '@/firebase';
import { ROOT_ACTIONS } from '@/store/actions';
import { USER_GETTERS } from '@/store/user/getters';
import { Phase } from '@/types';
import { format } from 'date-fns';
import { fr, nlBE } from 'date-fns/locale';
import { collection, collectionGroup, getDoc, getDocs, orderBy, query, where, doc, Timestamp, addDoc, updateDoc } from 'firebase/firestore';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgramWebinarsCard extends Vue {
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) phases!: Phase[];

    open = false;
    webinars = [] as any[];

    registeredWebinars = [] as any[];
    registerForWebinarDialog = false;
    selectedWebinar = '';
    selectedPhase = '';

    get user() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    @Watch('registerForWebinarDialog')
    async registerForWebinarDialogChanged() {
        if (this.registerForWebinarDialog && this.webinars.length === 0) {
            await this.fetchWebinars();
        }
    }

    async fetchWebinars() {
        let fetchedEvents = await getDocs(query(collection(firestore, 'webinars'), where('start', '>=', new Date()), orderBy('start')));
        let events = fetchedEvents.docs.map(e => {
            return {
                ...e.data(),
                id: e.id,
                title: `${e.data().title} - ${format((e.data().start as Timestamp).toDate(), 'dd MMMM yyyy hh:mm', {
                    locale: this.user.language === 'nl-be' ? nlBE : fr
                })}`,
                start: (e.data().start as Timestamp).toDate(),
                end: (e.data().end as Timestamp).toDate(),
            }
        });
        this.webinars = events;
        const registeredEvents = await getDocs(query(collection(firestore, 'event-attendees'), where('user', '==', this.userId)));
        // create a list with name + teams url in registeredWebinars
        this.registeredWebinars = [];
        for(const registeredEvent of  registeredEvents.docs){
            // get the webinar
            const webinar = await getDoc(doc(firestore, 'webinars', registeredEvent.data().webinarId));
            const coach = await getDoc(doc(firestore, 'users', registeredEvent.data().coach));
            if(!webinar.exists()) continue;
            if(!coach.exists()) continue;
            this.registeredWebinars.push({
                title: webinar?.data().title,
                ...registeredEvent.data(),
                coach: `${coach.data().firstname} ${coach.data().lastname}`,
                url: webinar.data().teamsUrl
            });
        }
    }

    async mounted() {
        await this.fetchWebinars();
    }

    @Watch('phases', { immediate: true })
    async onPhasesChange() {
        for (const phase of this.phases) {
            const start = phase.start.toDate();
            const end = phase.end.toDate();
            const now = new Date();
            if (now >= start && now <= end) {
                this.selectedPhase = phase.id!;
                break;
            }
        }
    }

    async registerForWebinar() {
        if (!this.selectedWebinar) return;

        let coach = '';

        if (this.user.type === 'ADMIN') {
            const programsOfUser = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', this.userId)))
            if (!programsOfUser.empty) {
                coach = programsOfUser.docs[0].data().coach as string;
            } else {
                coach = this.user.id;
            }
        } else {
            coach = this.user.id;
        }

        const attendeeDoc = await addDoc(collection(firestore, 'event-attendees'), {
            webinarId: this.selectedWebinar,
            user: this.userId,
            coach,
            present: false,
            addedActivity: false,
        })

        await addDoc(collection(firestore, 'webinar-invite-test'), {
            user: this.userId,
            webinar: this.selectedWebinar,
        })

        // Query to find the webinar activity
            const activitiesInPhase = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', this.selectedPhase)));
            // Find an activity where the name contains 'webinar' (lowercase)
            const activity = activitiesInPhase.docs.find(doc => doc.data().name.toLowerCase().includes('webinar'));
            const webinar = await getDoc(doc(firestore, 'webinars', this.selectedWebinar));
            this.registerForWebinarDialog = false;


            if (activity && webinar.exists()) {
                // Add the actual activity
                const actualActivityDoc = await addDoc(collection(firestore, 'actual-activities'), {
                    actualHours: 2,
                    comment: `Webinar: ${webinar.data().title}`,
                    activityId: activity.id,
                    date: webinar.data().start,
                    participantConfirmed: true,
                    participantPresent: false
                });

                await updateDoc(doc(firestore, 'event-attendees', attendeeDoc.id), {
                    actualActivityId: actualActivityDoc.id,
                });
            } else {
                this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                    color: 'orange',
                    text: 'No webinar activity found in this phase',
                });
            }


        this.registerForWebinarDialog = false;
        this.$emit('refresh');
    }
}
